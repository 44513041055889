import { render, staticRenderFns } from "./TableComponent.vue?vue&type=template&id=0ca1075a&scoped=true&transition=scale-transition&"
import script from "./TableComponent.vue?vue&type=script&lang=js&"
export * from "./TableComponent.vue?vue&type=script&lang=js&"
import style0 from "./TableComponent.vue?vue&type=style&index=0&id=0ca1075a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ca1075a",
  null
  
)

export default component.exports